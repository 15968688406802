import Footer from "../../components/footer"
import Header from "../../components/header"
import SideBar from "../../components/sidebar"
import services from "../../services"
import { Link, useNavigate } from "react-router-dom";
import Toaster from "../../components/toaster";
import { useState } from "react";
import { requestPermission } from "../../firebase";

import { PushNotifications } from '@capacitor/push-notifications';
const Login = () => {
    let navigate = useNavigate();

    const [phoneNumber, setPhoneNumber] = useState('')
    const [otp, setOtp] = useState('')
    const [response, setresponse] = useState('')
    const [toaster, setToaster] = useState({ visiblity: "hide" });
    function isRunningInCapacitor() {
        return window.Capacitor !== undefined;
    }
    const handleGenerateOTP = async (event) => {
        // event.preventDefault();
        // navigate("/users");
        try {
            let payLoad = {
                phoneNumber: phoneNumber,
                countryCode: '+91',
            }

            const response = await services.auth.GENERATE_OTP(payLoad);
            if (response.data) {
                setresponse(response.data)
            }

            if (response) {
                setToaster({
                    type: "success",
                    title: "Successful",
                    text: "OTP sent successfully",
                    visiblity: "show",
                });

            }


        } catch (error) {
            setToaster({
                type: "danger",
                title: "Error Occured",
                text: error?.response?.data?.message,
                visiblity: "show",
            });
            setTimeout(() => {
                setToaster({
                    visiblity: "hide",
                });
            }, 1500);
        }

    };
    const getToken = async () => {
        try {
            const token = await requestPermission();
            if (token) {
                console.log(token, 'token')
                submitOtp(token)
            }
        } catch (error) {
            console.log(error)
            if (error == 'Unable to get permission to notify.') {
                submitOtp()
                setToaster({
                    type: "danger",
                    title: "Failed",
                    text: error,
                    visiblity: "show",
                });
            } else {
                getToken();
            }

        }
    }
    const handleMobileToken = async () => {
        if (isRunningInCapacitor() && window.Capacitor.platform !== 'web') {
            console.log('The app is running inside a Capacitor environment.',window.Capacitor.platform !== 'web');
            // Register with push notifications
        PushNotifications.requestPermissions().then(result => {
            if (result.receive === 'granted') {
                PushNotifications.register();
            } else {
                console.log('Push notification permission not granted');
            }
        });

        // On receiving a notification
        PushNotifications.addListener('pushNotificationReceived', (notification) => {
            console.log('Push notification received:', notification);
        });

        // On receiving a notification in the foreground
        PushNotifications.addListener('pushNotificationActionPerformed', (notification) => {
            console.log('Push notification action performed:', notification);
        });
        PushNotifications.addListener('registration', (token) => {
            console.log('Push registration token:', token.value);
            submitOtp(token.value)
            // Save this token or send it to your server
        });
        } else {
            getToken()
            console.log('The app is running in a standard web environment.');
        }
        
    }
    const submitOtp = async (token) => {
        try {
            let payload = {
                phoneNumber: phoneNumber,
                otp: otp,
                countryCode: '+91',

            }
            if (token) {

                payload.firebaseToken = token;
            }
            const response = await services.auth.LOGIN_WITH_OTP(payload)
            console.log(response)
            if (response.data) {
                if (response.data.driverDetail.approvalStatus == 'approved') {

                    setToaster({
                        type: "success",
                        title: "Login",
                        text: 'Login successfully',
                        visiblity: "show",
                    });
                    window.location.replace('/');
                } else {
                    setToaster({
                        type: "danger",
                        title: "Pending profile",
                        text: 'Your Profile is pending please contact admin',
                        visiblity: "show",
                    });

                }
            }

        } catch (error) {
            console.log(error)
            setToaster({
                type: "danger",
                title: "Failed",
                text: 'Invalid OTP',
                visiblity: "show",
            });
        }
    }
    return (<>
        <Header />
        <SideBar />
        <main class="main">
            <Toaster
                type={toaster.type}
                title={toaster.title}
                text={toaster.text}
                visiblity={toaster.visiblity}
            />
            <div class="site-breadcrumb" style={{ background: "url(assets/img/breadcrumb/01.jpg)" }}>
                <div class="container">
                    <h2 class="breadcrumb-title">Welcome to Cars53</h2>
                    <ul class="breadcrumb-menu">
                        <li><a href="index.html">Home</a></li>
                        <li class="active">Login</li>
                    </ul>
                </div>
            </div>


            <div class="login-area py-5">
                <div class="container">
                    <div class="col-md-5 mx-auto">
                        <div class="login-form">
                            <div class="login-header">
                                <img src="/assets/img/logo/logo.png" alt />
                                <p>Login with your Cars53 driver account</p>
                            </div>

                            {response ? <>
                                <div class="form-group">
                                    <label>Password</label>
                                    <input type="password" onChange={(e) => setOtp(e.target.value)}
                                        value={otp}
                                        className="form-control"
                                        name="password"
                                        onKeyDown={(e) => {
                                            e.key === "Enter"
                                                ? handleMobileToken()
                                                : setOtp(e.target.value);

                                        }} placeholder="Enter OTP" />
                                </div>  <div class="d-flex align-items-center">
                                    <button onClick={() => handleMobileToken()} type="submit" class="theme-btn"><i class="far fa-sign-in"></i> Submit OTP</button>
                                </div></> :
                                <> <div class="form-group">
                                    <label>Phone Number</label>
                                    <input type="number" value={phoneNumber}
                                        placeholder="Enter your phone"
                                        onChange={(e) => setPhoneNumber(e.target.value)}
                                        onKeyDown={(e) => {
                                            e.key === "Enter"
                                                ? handleGenerateOTP()
                                                : setPhoneNumber(e.target.value);
                                        }} class="form-control" />
                                    {phoneNumber.length > 10 && <small className="text-danger">Please enter valid phone number</small>}
                                </div>
                                    <div class="d-flex align-items-center">
                                        <button disabled={phoneNumber.length > 10} onClick={() => handleGenerateOTP()} type="submit" class="theme-btn"><i class="far fa-sign-in"></i> GET OTP</button>
                                    </div> </>
                            }



                            {/* <div class="login-footer">
                              
                                <div class="social-login">
                                    <p>Continue with social media</p>
                                    <div class="social-login-list">
                                        <a href="#"><i class="fab fa-facebook-f"></i></a>
                                        <a href="#"><i class="fab fa-google"></i></a>
                                        <a href="#"><i class="fab fa-twitter"></i></a>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>

        </main>
        <Footer />
    </>)
}
export default Login