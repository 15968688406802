import { Link } from "react-router-dom"
import { useEffect } from "react";
const Header = () => {
    useEffect(() => {

        scriptHandler();


    }, [])
    const scriptHandler = async () => {
        const script = document.getElementById("module-scripts");
        if (!script) {

            const scriptSources = [
                '/assets/js/jquery-3.6.0.min.js',
                '/assets/js/modernizr.min.js',
                '/assets/js/bootstrap.bundle.min.js',
                '/assets/js/imagesloaded.pkgd.min.js',
                '/assets/js/jquery.magnific-popup.min.js',
                '/assets/js/isotope.pkgd.min.js',
                '/assets/js/jquery.appear.min.js',
                '/assets/js/jquery.easing.min.js',
                '/assets/js/owl.carousel.min.js',
                '/assets/js/counter-up.js',
                '/assets/js/jquery-ui.min.js',
                '/assets/js/jquery.timepicker.min.js',
                '/assets/js/jquery.nice-select.min.js',
                '/assets/js/wow.min.js',
            ];
            await Promise.all(scriptSources.map((src) => {
                const script = document.createElement('script');
                script.src = src;
                script.id = 'module-scripts'
                //   new
                // Append script to the body
                document.body.appendChild(script);
            }))
            const script = document.createElement('script');
            script.src = '/assets/js/main.js';
            script.id = 'module-scripts'
            //   new
            // Append script to the body
            document.body.appendChild(script);
        }
    }
    const logoutHandler = () =>{
        localStorage.clear();
        window.location.replace('/');
    }
    const isLoggedIn = localStorage.getItem('access_token');
    const isMobile = window.innerWidth < 768;
    return (
        <header className="header">

          
            <div className="main-navigation">
                <nav className="navbar navbar-expand-lg">
                    <div className="container position-relative">
                        <Link className="navbar-brand" to="/">
                            <img src="/assets/img/logo/logo.png" alt="logo" />
                        </Link>
                        
                        {isLoggedIn &&<div className="mobile-menu-right">
                           
                            <div class="nav-right-account">
                                <div class="dropdown">
                                    <div className="d-none">
                                        <img src="/assets/img/account/user.jpg" />
                                    </div>
                                    <ul class="dropdown-menu dropdown-menu-end">
                                        <li><Link class="dropdown-item" to="/"><i
                                            class="far fa-gauge-high"></i> Dashboard</Link></li>
                                        {/* <li><Link class="dropdown-item" to="/myprofile"><i class="far fa-user"></i> My
                                            Profile</Link></li> */}
                                        <li><Link class="dropdown-item" to="/mybookings"><i
                                            class="far fa-layer-group"></i> My Booking</Link></li>
                                          
                                        <li><Link class="dropdown-item" to="/payments"><i
                                            class="far fa-credit-card"></i>Payment History</Link></li>
                                            {isMobile && <li><a className="dropdown-item"><div id="google_translate_element" className="mobile-widget"></div></a></li>}
                                         
                                       
                                        <li onClick={logoutHandler}><a  onClick={logoutHandler} class="dropdown-item"><i class="far fa-sign-out"></i> Logout</a></li>
                                            
                                    </ul>
                                </div>
                            </div>
                          
                            <button className="navbar-toggler" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <span className="navbar-toggler-mobile-icon"><i className="far fa-bars"></i></span>
                            </button>
                        </div>}
                        
                        <div className="collapse navbar-collapse" id="main_nav">
                           
                            <div className="nav-right">
                                <div class="nav-right-account d-none">
                                   {isLoggedIn && <div class="dropdown">
                                        <div data-bs-toggle="dropdown" aria-expanded="false">
                                            <img src="/assets/img/account/user.jpg" />
                                        </div>
                                        <ul class="dropdown-menu dropdown-menu-end">
                                        <li><Link class="dropdown-item" to="/"><i
                                            class="far fa-gauge-high"></i> Dashboard</Link></li>
                                        <li><Link class="dropdown-item" to="/myprofile"><i class="far fa-user"></i> My
                                            Profile</Link></li>
                                        <li><Link class="dropdown-item" to="/mybookings"><i
                                            class="far fa-layer-group"></i> My Booking</Link></li>
                                            <li><Link class="dropdown-item" to="/cars"><i
                                            class="far fa-taxi"></i> My Cars</Link></li>
                                             <li><Link class="dropdown-item" to="/drivers"><i
                                            class="far fa-users"></i>Drivers</Link></li>
                                        <li><Link class="dropdown-item" to="/payments"><i
                                            class="far fa-credit-card"></i> Payment History</Link></li>
                                             <li><Link class="dropdown-item" to="/ratings"><i
                                            class="far fa-star"></i> My Ratings</Link></li>
                                        <li><Link class="dropdown-item" to="/settings"><i
                                            class="far fa-cog"></i> Settings</Link></li>
                                        <li onClick={logoutHandler}><a class="dropdown-item"><i class="far fa-sign-out"></i> Logout</a></li>
                                        </ul>
                                    </div>}
                                </div>
                                
                                <div className="sidebar-btn">
                                    <button type="button" className="nav-right-link"><i className="far fa-bars-filter"></i></button>
                                </div>
                            </div>
                        </div>
                        
                        
                        <div className="search-area">
                            <form action="#">
                                <div className="form-group">
                                    <input type="text" className="form-control" placeholder="Type Keyword..." />
                                    <button type="submit" className="search-icon-btn"><i className="far fa-search"></i></button>
                                </div>
                            </form>
                        </div>

                    </div>
                </nav>
            </div>
        </header>
    )
}
export default Header