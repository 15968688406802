    import { Link } from "react-router-dom"

    const DashboardSideBar = () => {
        const logoutHandler = () =>{
            localStorage.clear();
            window.location.replace('/');
        }
        return (
            <div class="col-lg-3 my-4 my-lg-0">
                <div class="user-profile-sidebar d-none d-lg-block">
                    <div class="user-profile-sidebar-top">
                        <div class="user-profile-img">
                            <img src="/assets/img/account/user.jpg" alt />
                            <button type="button" class="profile-img-btn"><i class="far fa-camera"></i></button>
                            <input type="file" class="profile-img-file" />
                        </div>
                        <h5>Antoni Jonson</h5>
                        <p><a>email@gmail.com</a>
                        </p>
                    </div>
                    <ul class="user-profile-sidebar-list">
                        <li><Link to="/"><i
                            class="far fa-gauge-high"></i> Dashboard</Link></li>
                        {/* <li><Link to="/myprofile"><i class="far fa-user"></i> My
                            Profile</Link></li> */}
                        <li><Link to="/mybookings"><i
                            class="far fa-layer-group"></i> My Booking</Link></li>
                
                        <li><Link to="/payments"><i
                            class="far fa-credit-card"></i> Payment History</Link></li>
                    
                        
                        <li><a href="#" onClick={logoutHandler}><i class="far fa-sign-out"></i> Logout</a></li>
                        <li><a href="#"><div id="google_translate_element" className="mobile-widget"></div></a></li>
                    </ul>
                </div>
            </div>
        )
    }
    export default DashboardSideBar